.ChangePw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 0 10px 12px 0;
  border-bottom: #e5e5e5 1px solid;
}

.ChangePw div {
  display: flex;
  flex-direction: column;
  i {
    margin-bottom: 4px;
    font-style: normal;
  }
  span {
    color: #999;
    font-size: 12px;
  }
}

.renewDataBut {
  width: 100%;
  overflow: hidden;
}
