body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/** 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}

div {
  user-select: none;
}

td {
  user-select: text !important;
}

.ant-picker-content td,
.ant-picker-content th {
  user-select: auto !important;
}

.ant-picker-cell .ant-picker-cell-inner {
  user-select: text;
  -webkit-app-region: no-drag;
}

.ant-form-small .ant-form-item-label > label {
  height: 24px;
  font-size: 13px;
}

.ant-dropdown {
  -webkit-app-region: no-drag;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0 0 !important;
}

.ant-upload.ant-upload-drag {
  border: none !important;
}

.ant-table.ant-table-small {
  font-size: 12px !important;
}
