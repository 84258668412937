.LoginBox {
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-size: 100% 120%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notice {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  padding: 24px;
}

.loginpane {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  padding: 24px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  padding: 0 6px;
}

.Content {
  width: 100%;
  z-index: 10;
  min-width: 300px;
  max-width: 400px;
}
.Content h1 {
  font-size: 60px;
  color: #fff;
  font-weight: bold;
}

.Content .CardDiv {
  border-radius: 5px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 12px;
}
.Card {
  width: 100%;
  border: none;
}
.Title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.Tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.Copyright {
  position: absolute;
  bottom: 30px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.antTabsNavList {
  justify-content: center;
  display: flex;
}
.ant-tabs-ink-bar {
  left: 72px;
  width: 84px;
}

.remember div {
  display: flex;
  justify-content: space-between;
}
