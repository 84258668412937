.layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.box {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-width: 800px;
  min-height: 560px;
  max-width: 800px;
  max-height: 600px;
  box-shadow: 0 0 10px 2px #eee;
  border-radius: 10px;
  overflow: hidden;
}

.sider {
  position: relative;
  flex: 0 0 200px;
  width: 200px;
  height: 100%;
  background-color: #1890ff;
  padding: 20px;
  box-sizing: border-box;
  -webkit-app-region: drag; /* This is the key to make the window draggable */
}

.sider .title_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 0 20px 0;
  border-bottom: 2px solid #1890ff;
}

.sider .name {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.sider .logo {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 6px;
  margin-right: 6px;
}

.sider .menu {
  margin-top: 20px;
}

.sider .menu .item {
  margin-bottom: 10px;
  padding: 10px 0;
  text-align: center;
  color: #666;
  background-color: #f5f5f5;
  cursor: pointer;
  border-radius: 6px;
  -webkit-app-region: no-drag;
}

.sider .menu .item_active {
  color: #1890ff !important;
  background-color: #ffffff !important;
  font-weight: 700;
}

.sider .menu .item:hover {
  color: #1890ff;
  background-color: #f5f5f5;
}

.sider .menu .item .link {
}

.sider .menu .item .icon {
  margin-right: 6px;
}

.sider .footer {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 40px);
  -webkit-app-region: no-drag;
}

.sider .footer_item {
  color: #fff;
  font-size: 13px;
}

.content {
  flex: 1;
  height: 100%;
  border-top: 1px solid #eee;
}

.content .header {
  height: 24px;
  -webkit-app-region: drag;
  /* background-color: #f2f0f2; */
}

.content .main {
  width: 100%;
  height: calc(100% - 24px);
  overflow: auto;
}
