.home {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_img {
  width: 400px;
  height: 400px;
  margin-right: 40px;
}

.home_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.name {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
}

.avatar_box {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
}
.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #fff;
  border: #0396ff 1px solid;
  border-radius: 50%;
}

.time {
  margin-top: 12px;
  font-size: 14px;
}

.card {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: #eee 0 0 10px 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.card:hover {
  box-shadow: #1890ff33 0 0 10px 2px;

  .card_title {
    color: #1890ff;
  }

  .card_subtitle {
    color: #1890ffaa;
    font-size: 12px;
  }
}

.card_icon {
  width: 50px;
  height: 50px;
  background-color: orange;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_title {
  font-weight: 500;
  font-size: 18px;
  transition: all 0.3s;
}

.card_subtitle {
  color: #888;
  font-size: 12px;
  transition: all 0.3s;
}

.bg {
  width: 100%;
  height: 600px;
  opacity: 0.45;
}
