.form_item {
  margin-bottom: 12px;
}

.form_item .label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
}

.form_item .tips {
  margin-top: 4px;
  font-size: 12px;
  color: #666;
}
